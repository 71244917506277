<template>
  <div>
    <NavBar name="巡检计划表"></NavBar>
    <div class="datapreview">
      <el-form :inline="true" class="margintop30">
        <div>
          <el-form-item>
            <MyButton :accessId="847223" title="新增计划" @click="setShow('add')">
              <template slot="preImage">
                <img src="@/unit/img/zj.png" alt />
              </template>
            </MyButton>
          </el-form-item>
        </div>
        <div></div>
      </el-form>
      <div class="pub-table">
        <el-table :data="tableData" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }" v-loading="loading">
          <el-table-column label="计划名称" prop="inspection_task_name"></el-table-column>
          <el-table-column label="计划类型">
            <template #default="{ row }">{{
              row.inspection_type == 10 ? "固定线路" : "非固定线路"
            }}</template>
          </el-table-column>
          <el-table-column label="巡检线路" prop="way"></el-table-column>
          <el-table-column label="巡检点位数量">
            <template #default="{ row }">{{
              row.way_list ? row.way_list.length : 0
            }}</template>
          </el-table-column>
          <el-table-column label="巡检人员" prop="worker_name"></el-table-column>
          <el-table-column label="计划开始时间" prop="task_time"></el-table-column>
          <el-table-column label="计划结束时间" prop="task_end_time"></el-table-column>
          <el-table-column label="计划间隔时间（分钟）" prop="task_interval_time"></el-table-column>
          <!-- <el-table-column label="异常问题数" prop="log_number">
            <template
              #default="{row}"
            ><div>{{row.task_abnormal_log_list ? row.task_abnormal_log_list.length : 0}}</div></template>
          </el-table-column> -->
          <el-table-column label="操作" prop="inspection_task_name">
            <template #default="{ row }">
              <pop :accessId="847224" tips="更新" @myclick="setShow('edit', row)" popRight>
                <img class="icon" src="@/unit/img/edit.png" alt />
              </pop>
              <pop :accessId="847225" tips="删除" @myclick="deleted(row)">
                <img class="icon" src="@/assets/img/icon/deleted.png" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
        <div class="apiPage">
          <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="10"
            @current-change="getList" :current-page="form.pageNum"></el-pagination>
        </div>
      </div>
      <div class="desc-tips">
        <p>说明：1、固定线路：巡检员必须按设置的巡检线路按序进行巡检</p>
        <p>2、非固定线路：巡检员可随意巡检点位</p>
        <p>3、计划间隔时间：巡检计划按设置的间隔时间反复进行</p>
      </div>
    </div>
    <el-dialog title="新增/编辑计划" :visible.sync="dialogVisible" width="880px" @close="handleClose"
      :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="计划类型" prop="inspection_type">
          <el-col :span="14">
            <el-radio-group v-model="ruleForm.inspection_type">
              <el-radio :label="10">固定路线</el-radio>
              <el-radio :label="20">非固定路线</el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>
        <el-form-item label="巡检计划名称" prop="inspection_task_name">
          <el-col :span="14">
            <el-input v-model.trim="ruleForm.inspection_task_name" class="public-input"
              placeholder="请输入巡检计划名称"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="巡检点位设置" prop="inspection_ids">
          <div class="boxList">
            <div class="list" style="display: flex; margin: 0 0 10px 0"
              v-for="(domains, index) in ruleForm.inspection_ids" :key="index">
              <el-form-item :prop="`inspection_ids[${index}].id`"
                :rules="{ required: true, message: '请选择标点', trigger: 'change' }">
                <el-select v-model="domains.id" class="public-input" placeholder="请选择标点">
                  <el-option v-for="list in pointList" :disabled="selectedspot(list.inspection_id)"
                    :key="list.inspection_id" :label="list.inspection_sort" :value="list.inspection_id"></el-option>
                </el-select>
              </el-form-item>
              <MyButton @click="delspot(index)" left>删除</MyButton>
            </div>
            <div class>
              <MyButton v-if="addBtndis < pointList.length - 1" @click="addInspection">添加</MyButton>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="巡检开始时间" prop="task_time">
          <el-col :span="14">
            <el-time-picker format="HH:mm:ss" value-format="HH:mm:ss" v-model="ruleForm.task_time" class="public-input"
              placeholder="巡检开始时间"></el-time-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="巡检结束时间" prop="task_end_time">
          <el-col :span="14">
            <el-time-picker format="HH:mm:ss" value-format="HH:mm:ss" v-model="ruleForm.task_end_time"
              class="public-input" placeholder="巡检结束时间"></el-time-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="巡检间隔时间" prop="task_interval_time" required>
          <el-col :span="14">
            <el-input v-model.trim="ruleForm.task_interval_time" class="public-input" type="number"
              placeholder="请输入巡检间隔时间"></el-input>
            <span>分钟</span>
          </el-col>
        </el-form-item>
        <el-form-item label="巡检提醒设置" prop="task_remind_time">
          <el-col :span="14">
            <span>巡检开始前</span>
            <el-input v-model="ruleForm.task_remind_time" class="public-input" placeholder="请输入巡检提醒设置"></el-input>
            <span>分钟发送信息提醒</span>
          </el-col>
        </el-form-item>
        <el-form-item label="巡检人员选择" prop="workers">
          <el-col :span="14">
            <el-select v-model="changeWorkersArr" @change="changeWorkers" multiple collapse-tags clearable
              class="public-input" placeholder="请选择">
              <el-option v-for=" item  in  workerList " :key="item.user_id" :label="item.name"
                :value="item.user_id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" right @click="dialogVisible = false"></MyButton>
        <MyButton title="确认" type="primary" @click="confirm"></MyButton>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      let start = new Date(
        "December 17, 1995 " + this.ruleForm.task_time
      ).getTime();
      let end = new Date(
        "December 17, 1995 " + this.ruleForm.task_end_time
      ).getTime();
      let cj = (end - start) / 1000 / 60;
      if (value === "") {
        callback(new Error("请输入巡检间隔时间"));
      } else if (value > cj) {
        callback(new Error("请注意间隔时间与开始和结束时间的差"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      tableData: [],
      page: 1,
      dialogVisible: false,
      addBtndis: 0,
      workerList: [],
      rules: {
        inspection_task_name: [
          { required: true, message: "请输入计划名称", trigger: "blur" },
        ],
        task_interval_time: [{ validator: validatePass, trigger: "blur" }],
        task_time: [
          { required: true, message: "请选择巡检开始时间", trigger: "change" },
        ],
        task_end_time: [
          { required: true, message: "请选择巡结束始时间", trigger: "change" },
        ],
        inspection_ids: [
          { required: true, message: "请输入点位", trigger: "click" },
        ],
        inspection_type: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        task_remind_time: [
          { required: true, message: "请输入巡检提醒设置", trigger: "blur" },
        ],
        workers: [
          { required: true, message: "请选择巡检人员", trigger: "change" },
        ],
      },
      ruleForm: {
        inspection_task_name: "",
        task_interval_time: "",
        task_time: "",
        task_end_time: "",
        inspection_ids: [],
        inspection_type: 10,
        task_remind_time: "",
        workers: [],
      },
      pointList: [],
      workerList: [],
      form: {
        pageNum: 1,
        pageSize: 10,
        market_name: "",
        market_contacts: "",
      },
      changeWorkersArr: [],
      total: 0,
    };
  },
  created () {
    this.getWorkerList();
    this.getPointList();
    this.getList();
  },
  methods: {
    changeWorkers (e) {
      if (e.length > 0) {
        let tempArr = [];
        this.workerList.forEach((item) => {
          if (e.indexOf(item.user_id) != -1) {
            tempArr.push({
              worker_id: item.user_id,
              worker_name: item.name
            });
          }
        });
        this.ruleForm.workers = tempArr;
      } else {
        this.ruleForm.workers = [];
      }
    },
    getList (e = 1) {
      this.loading = true;
      this.form.pageNum = e;
      this.tableData = [];
      this.$request.HttpPost("/inspection_task/list", this.form).then((res) => {
        this.loading = false;
        if (res.data) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    setShow (type, data) {
      this.isAdd = type;
      if (type == "edit") {
        this.$request.HttpGet('/inspection_task/get', {
          id: data.inspection_task_id
        }).then((res) => {
          for (let i in this.ruleForm) {
            this.ruleForm[i] = res.data[i];
          }
          this.ruleForm.workers = [];
          if (res.data.worker) {
            res.data.worker.forEach((item) => {
              this.ruleForm.workers.push({
                worker_id: item.worker_id,
                worker_name: item.worker_name
              });
              this.changeWorkersArr.push(item.worker_id);
            });
          }
          this.ruleForm.inspection_ids = [];
          this.ruleForm.inspection_task_id = data.inspection_task_id;
          if (res.data.wayList) {
            res.data.wayList.forEach((item) => {
              this.ruleForm.inspection_ids.push({ id: item.inspection_id });
            });
          }
          this.dialogVisible = true;
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate();
          });
        })
      } else {
        this.ruleForm.inspection_ids = [{ id: "" }];
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate();
        });
      }
    },
    getPointList () {
      this.pointList = [];
      this.$request.HttpGet('/inspection_setting/list', {
        pageNum: 1,
        pageSize: 9000
      }).then((res) => {
        if (res.data && res.data.list) {
          res.data.list.forEach((item) => {
            if (item.values) {
              item.values = JSON.parse(item.values);
            }
            item.inspection_id = Number(item.inspection_id);
            this.pointList.push(item);
          });
          console.log(this.pointList);
        }
      });
    },
    getWorkerList () {
      this.$request
        .HttpGet("/worker/list", {
          pageNum: 1,
          pageSize: 1000,
        })
        .then((res) => {
          this.workerList = res.data.list;
        });
    },
    // 删除标点
    delspot (index) {
      this.addBtndis -= 1;
      this.ruleForm.inspection_ids.splice(index, 1);
    },
    addInspection () {
      this.addBtndis += 1;
      this.ruleForm.inspection_ids.push({
        id: "",
      });
    },
    // 禁用标点
    selectedspot (e) {
      let isExisd = this.ruleForm.inspection_ids.some((item) => {
        return item.id == e;
      });
      return isExisd;
    },
    handleClose () {
      this.addBtndis = 0;
      this.changeWorkersArr = [];
      this.ruleForm = this.$options.data().ruleForm;
      this.$refs.ruleForm.clearValidate();
    },
    confirm () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let url =
            this.isAdd == "edit"
              ? "/inspection_task/edit"
              : "/inspection_task/add";
          this.$request.HttpPost(url, this.ruleForm).then((res) => {
            this.$common.notifySuccess(res.msg);
            this.getList();
            this.dialogVisible = false;
          });
        }
      });
    },
    deleted (item) {
      this.$myconfirm("确认删除该计划").then(() => {
        this.$request
          .HttpPost("/inspection_task/delete", {
            inspection_task_ids: [item.inspection_task_id],
          })
          .then((res) => {
            this.$common.notifySuccess("删除成功");
            this.getList();
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.datapreview {
  margin-top: 20px;
}

/deep/ .el-form--inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pub-table {
  height: calc(100vh - 280px);
}

.taskBox {
  span {
    &:last-child {
      &:after {
        content: "";
      }
    }

    &:after {
      content: "→";
    }
  }
}

.overtime {
  color: green;
}

.normal {
  color: black;
}

.reds {
  color: red;
}
</style>
