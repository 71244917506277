<template>
  <div>
    <NavBar name="巡检监管"></NavBar>
    <div class="datapreview" style="margin-top: 20px">
      <div class="pub-table margintop30">
        <el-table :data="tableData" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }" v-loading="loading">
          <el-table-column label="巡检计划名称" prop="inspection_task_name"></el-table-column>
          <el-table-column label="巡检线路" prop="inspection_task_name">
            <template #default="{ row }">
              <div v-if="row.inspection_type == 10">
                <span v-for="(item, index) in row.way_list" :key="item.way_id">
                  <span :style="{ color: item.colour == 1 ? 'green' : item.colour == 2 ? '#E90000' : 'black' }">{{
                    item.inspection_sort }}</span>
                  <span v-if="index < row.way_list.length - 1 && item.colour == 1" style="color: green">→</span>
                  <span v-if="index < row.way_list.length - 1 && item.colour == 2" style="color: #e90000">→</span>
                  <span v-if="index < row.way_list.length - 1 && item.colour == 0" style="color: black">→</span>
                </span>
              </div>
              <div v-else>
                <span v-for="(item, index) in row.way_list" :key="item.way_id">
                  <span v-if="item.colour == 1" style="color: green">{{
                    item.inspection_sort
                  }}</span>
                  <span v-else-if="item.colour == 2" style="color: #e90000">{{
                    item.inspection_sort
                  }}</span>
                  <span v-else style="color: black">{{
                    item.inspection_sort
                  }}</span>
                  <span v-if="index < row.way_list.length - 1">,</span>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="巡检人员" prop="worker_name"></el-table-column>
          <el-table-column label="计划开始时间" prop="task_time"></el-table-column>
          <el-table-column label="计划结束时间" prop="task_end_time"></el-table-column>
          <el-table-column label="异常问题数" prop="abnormal_count">
            <!-- <template #default="{ row }">
              <span @click="showabnormal(row)" style="cursor: pointer">{{
                row.log_number
              }}</span>
            </template> -->
          </el-table-column>
        </el-table>
        <div class="apiPage">
          <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="10"
            :current-page="form.pageNum" @current-change="getList"></el-pagination>
        </div>

        <!-- wenti  -->
        <el-dialog title="异常问题" :visible.sync="normalvisible" :close-on-click-modal="false">
          <el-table :data="abnormdata">
            <el-table-column prop="inspection_abnormal" label="问题说明"></el-table-column>
            <el-table-column prop="create_at" label="发现时间"></el-table-column>
            <el-table-column prop="status" label="处理情况">
              <template #default="{ row }">
                <span v-if="row.abnormal_status == 20">已处理</span>
                <span v-else style="color: #e90000">未处理</span>
              </template>
            </el-table-column>
            <el-table-column prop="inspection_time" label="处理时间"></el-table-column>
            <el-table-column prop="abnormal_worker_name" label="处理人"></el-table-column>
          </el-table>
        </el-dialog>
      </div>
      <!-- <div class="desc-tips">
        点位颜色说明：绿色表示该点位已巡检并在间隔时间之内；红色表示该点位超过了间隔时间没有进行扫码巡检；黑色表示该点位还没有进行扫码巡检
      </div> -->
      <div class="desc-tips">
        点位颜色说明：绿色表示该点位已巡检并在间隔时间之内；黑色表示该点位还没有进行扫码巡检
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      tableData: [],
      pointList: [],
      form: {
        pageNum: 1,
        pageSize: 10,
        market_name: "",
        market_contacts: "",
      },
      abnormdata: [],
      normalvisible: false,
      total: 0,
    };
  },
  created () {
    this.getList();
  },
  methods: {
    getList (e = 1) {
      this.loading = true;
      this.form.pageNum = e;
      this.tableData = [];
      this.$request
        .HttpPost("/inspection_task/administratorList", this.form)
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    setShow (type, item = {}) {
      this.isAdd = type;
      console.log(item);
      if (type) {
        this.setform.name = "";
      } else {
        this.setform.name = item.type_name;
      }
      this.visible = true;
    },
    handleClose () { },
    showabnormal (row) {
      this.abnormdata = row.log_list;
      this.normalvisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-form--inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pub-table {
  height: calc(100vh - 280px);
}
</style>
